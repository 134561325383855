@mixin size($w, $h: $w) {
  width: $w;
  height: $h;
}

@mixin selection {
  &::-webkit-selection {
    @content;
  }
  &::-moz-selection {
    @content;
  }
  &::selection {
    @content;
  }
}

@mixin image($file) {
  $url: '../../img/#{$file}';
  background-image: url($url);
}

@mixin full {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

@mixin sheet($position: absolute) {
  position: $position;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

@mixin background-cover {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

@mixin background-contain {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

@mixin fontSrc($name) {
  src: url('../../fonts/#{$name}.woff2') format('woff2'), url('../../fonts/#{$name}.woff') format('woff');
}

//MEDIA queries

@mixin mobile {
  @media only screen and (max-width: 767px) {
    @content;
  }
}

@mixin desktop {
  @media only screen and (min-width: 768px) {
    @content;
  }
}

@mixin tablet {
  @media only screen and (max-width: 1280px) {
    @content;
  }
}
