.grid {
  display: flex;
  justify-content: space-between;
  width: 100%;

  @include mobile {
    flex-direction: column;
  }

  &__col {
    flex-shrink: 0;
    &--r {
      width: 66.4rem;
      @include mobile {
        width: 100%;
      }
    }

    &--l {
      width: 30rem;
      @include mobile {
        width: 100%;
        margin-bottom: 4rem;
      }
    }
  }
}
