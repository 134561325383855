.heading {
  font-weight: 500;

  &--l {
    line-height: calc(52 / 44);
    font-size: 4.4rem;
    font-weight: 700;

    @include mobile {
      font-size: 2.6rem;
      line-height: calc(36 / 26);
    }
  }

  &--m {
    line-height: calc(44 / 34);
    font-size: 3.4rem;
    font-weight: 500;

    @include mobile {
      font-size: 2.6rem;
      line-height: calc(36 / 26);
    }
  }

  &--s {
    line-height: calc(30 / 22);
    font-size: 2.2rem;
    font-weight: 500;

    @include mobile {
      font-size: 1.8rem;
      line-height: calc(26 / 18);
    }
  }
}
