.logo {
  width: 19rem;
  height: 6rem;
  user-select: none;
  transition: height 300ms ease-in-out, width 300ms ease-in-out;
  will-change: height, width;

  @include mobile {
    height: 4rem;
    width: 12.7rem;
  }

  svg {
    width: 100%;
    height: 100%;
  }
}
