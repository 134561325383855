.text-block {
  width: 100%;
  font-size: 1.6rem;
  line-height: calc(24 / 16);

  @include mobile {
    font-size: 1.4rem;
    line-height: calc(22 / 14);
  }

  &__header-link {
    display: inline-block;
    user-select: none;
    cursor: pointer;
  }

  b,
  strong {
    font-weight: 500;
  }

  a,
  &__header-link {
    display: inline-flex;
    overflow: hidden;
    color: $green;
    position: relative;
    user-select: none;
    color: $green-dark;

    &:after {
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: $green-dark;
      transform: translateX(-100%);
      transition: none;
      content: '';
      animation: moveIn 0.32s ease-in-out forwards;
      animation-delay: 0.32s;
    }

    @include desktop {
      &:hover {
        &::after {
          animation: moveOut 0.32s ease-in-out forwards;
        }
      }
    }
  }

  img {
    max-width: 100%;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    + p,
    + ul,
    + ol,
    + table {
      margin-top: 4rem;
    }
  }

  ol {
    padding-left: 0;
    counter-reset: list;
    li {
      list-style: none;
      padding-left: 2.3rem;
      position: relative;
      &:before {
        counter-increment: list;

        content: counter(list) '.';
        display: inline-block;

        left: 0;
        top: 0;

        color: $green-dark;
        position: absolute;
      }
    }
  }

  ol,
  ul {
    li {
      &:not(:last-of-type) {
        margin-bottom: 1em;
      }
    }

    + p,
    + table {
      margin-top: 3rem;
    }
    + h1,
    + h2,
    + h3,
    + h4,
    + h5,
    + h6 {
      margin-top: 4rem;
    }
  }

  ul {
    li {
      position: relative;
      padding-left: 2rem;

      &:before {
        content: '';
        display: block;
        width: 0.4rem;
        height: 0.4rem;
        border-radius: 50%;
        left: 0;
        top: 1.2rem;
        transform: translateY(-50%);
        background-color: $green-dark;
        position: absolute;
      }
    }
  }

  p {
    + p,
    table {
      margin-top: 2rem;
    }

    + ul,
    + ol {
      margin-top: 3rem;
    }
    + h1,
    + h2,
    + h3,
    + h4,
    + h5,
    + h6 {
      margin-top: 4rem;
    }
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 500;
    line-height: calc(30 / 22);
    font-size: 2.2rem;

    @include mobile {
      font-size: 1.8rem;
      line-height: calc(26 / 18);
    }

    + h2,
    + h3,
    + h4,
    + h5,
    + h6,
    + h1 {
      margin-top: 3rem;
    }
  }

  h1 {
    line-height: calc(52 / 44);
    font-size: 4.4rem;
    font-weight: 700;

    @include mobile {
      font-size: 2.6rem;
      line-height: calc(36 / 26);
    }
  }
  h2 {
    line-height: calc(44 / 34);
    font-size: 3.4rem;

    @include mobile {
      font-size: 2.6rem;
      line-height: calc(36 / 26);
    }
  }
}
