.hero {
  display: flex;
  min-height: 100vh;
  width: 100%;
  padding: 10rem 0;
  background-color: $green-light;
  position: relative;

  &__container {
    display: flex;
    align-self: center;
    width: 100%;

    @include mobile {
      flex-direction: column;
    }
  }

  &__wrapper {
    width: 50%;
    display: flex;
    position: relative;
    font-size: 0;

    @include mobile {
      width: 100%;
      + .hero__wrapper {
        margin-top: 4rem;
      }
    }
  }

  &__content {
    align-self: center;
    padding-right: 4rem;
    padding-bottom: 12rem;
    @include mobile {
      padding: 0;
    }
  }

  &__image {
    margin-bottom: -10rem;
    margin-left: auto;
    position: relative;
    z-index: 2;
    @include mobile {
      margin-bottom: -7rem;
    }

    img {
      width: 49.4rem;
      height: calc(100vh - 10rem);
      object-fit: cover;
      @include mobile {
        width: 100%;
        height: auto;
      }
    }
  }

  &__decor {
    display: flex;
    padding: 1rem 1rem 0 1rem;
    width: 100%;
    height: 10rem;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: $white;

    @include mobile {
      height: 9rem;
    }

    &--inner {
      flex: 1;
      @include image('pattern.svg');
    }
  }

  .scroll-to {
    position: absolute;
    left: 0;
    bottom: 4rem;

    @include mobile {
      display: none;
    }
  }
}
