.banner {
  width: 100%;
  // margin-bottom: -50vh;
  position: relative;
  z-index: 2;

  &__image {
    width: 100%;
    font-size: 0;

    img {
      width: 100%;
      @include mobile {
        min-height: 35rem;
        object-fit: cover;
      }
    }
  }
}
