*, *:before, *:after{
	box-sizing: border-box;
	box-shadow: none;
	border-radius: 0;
	background-color: transparent;
	color: inherit;
	outline: none;
	border: none;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	text-rendering: optimizeLegibility;
}

input, textarea, button, select{
	box-sizing: border-box;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
	-webkit-appearance: none;
}

select::-ms-expand {
	display: none;
}