.lds-roller {
  display: block;
  position: relative;
  width: 8rem;
  height: 8rem;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 4rem 4rem;
}
.lds-roller div:after {
  content: ' ';
  display: block;
  position: absolute;
  width: 0.7rem;
  height: 0.7rem;
  border-radius: 50%;
  background: $green-dark;
  margin: -0.4rem 0 0 -0.4rem;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 6.3rem;
  left: 6.3rem;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 6.8rem;
  left: 5.6rem;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 7.1rem;
  left: 4.8rem;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 7.2rem;
  left: 4rem;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 7.1rem;
  left: 3.2rem;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 6.8rem;
  left: 2.4rem;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 6.3rem;
  left: 1.7rem;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 5.6rem;
  left: 1.2rem;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader {
  @include sheet(fixed);
  display: flex;
  justify-content: center;
  align-items: center;
}
