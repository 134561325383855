$imgPath: 'client/src/img';
$trans: 150ms ease-in-out;

$font: 'Mark Pro', sans-serif;

$white: #ffffff;

$green-dark: #0b6670;
$green-light: #d5e9e1;
// $green-light: #d5e9e1;
$green: #d5e9e1;
// $green: #d5e9e1;
