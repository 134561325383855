.sub-title {
  display: inline-block;
  padding-left: 3.2rem;
  position: relative;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: calc(24 / 16);
  color: $green-dark;

  &::before {
    content: '';
    display: block;
    width: 1.2rem;
    height: 2px;
    background-color: $green-dark;
    position: absolute;
    left: 0;
    top: 0.75em;
  }

  &--light {
    color: $green-light;
    &::before {
      background-color: $green-light;
    }
  }
}
