@font-face {
  font-family: 'Mark Pro';
  @include fontSrc('MarkPro');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Mark Pro';
  @include fontSrc('MarkPro-Medium');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Mark Pro';
  @include fontSrc('MarkPro-Bold');
  font-weight: 700;
  font-style: normal;
}
