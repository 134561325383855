p {
  margin: 0;
}

p + p {
  //margin-top: 25px;
}

a {
  text-decoration: none;
}

ul {
  margin: 0;
  padding: 0;
  li {
    list-style: none;
  }
}
h1 {
  margin: 0;
  font-weight: 700;
}

h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: 500;
}
