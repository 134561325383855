.footer {
  width: 100%;
  padding: 12rem 0 15.5rem 0;
  background-color: $green-dark;
  position: relative;

  @include mobile {
    padding: 8rem 0 13rem 0;
  }

  &__bottom {
    display: flex;
    align-items: center;
    margin-top: 10rem;

    @include mobile {
      margin-top: 7rem;
    }

    &--l {
      display: flex;
      width: 100%;

      .list {
        margin-left: auto;
        @include mobile {
          order: -1;
          margin-bottom: 1rem;
          margin-left: unset;
        }
      }

      @include mobile {
        flex-direction: column;
      }

      // @include mobile {
      //   // width: 65%;
      //   flex-direction: column;
      // }
    }
  }

  &__legal,
  &__created-by {
    display: inline-block;
    font-size: 1.6rem;
    color: $white;
    opacity: 0.5;
    line-height: calc(24 / 16);
    margin-right: 0.5rem;

    @include mobile {
      margin-right: 0;
      font-size: 1.4rem;
      line-height: calc(22 / 14);
    }
  }

  &__created-by {
    display: inline-flex;
    align-items: center;
    margin-left: auto;

    @include mobile {
      max-width: 30%;
      flex-direction: column;
    }
    a {
      margin-left: 0.5em;
      @include mobile {
        margin-left: 0;
      }
    }

    svg {
      height: 1.4rem;
    }
  }

  &__decor {
    display: flex;
    padding: 0 1rem 1rem 1rem;
    width: 100%;
    height: 10rem;
    position: absolute;
    bottom: 0;
    left: 0;

    &--inner {
      flex: 1;
      @include image('pattern-white.svg');
    }
  }

  &--isVisible {
    .link--contacts {
      &::after {
        animation: moveIn 0.32s ease-in-out forwards;
      }
    }
  }

  .grid {
    .text-block {
      color: $white;
      padding-left: 4rem;
      border-left: 2px solid $green;

      @include mobile {
        padding-left: 2rem;
      }
      ul {
        li {
          font-weight: 500;
          padding-left: 0;
          &::before {
            display: none;
          }

          &:not(:last-of-type) {
            font-size: 2.2rem;
            margin-bottom: 1.2rem;

            @include mobile {
              font-size: 1.8rem;
              margin-bottom: 2rem;
            }

            a {
              line-height: 1.6;
              &::after {
                animation: moveOut 0.32s ease-in-out forwards;
              }

              &:hover {
                &::after {
                  animation: moveIn 0.32s ease-in-out forwards;
                }
              }
            }
          }
        }
      }
      a {
        color: $white;
        line-height: 2;
        &::after {
          background-color: $white;
        }
      }
    }
  }
}
