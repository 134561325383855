.section {
  + .section {
    padding-top: 12rem;
    @include mobile {
      padding-top: 8rem;
    }
  }

  &.hero {
    + .section {
      padding-top: 5.3rem;
      padding-top: 12rem;
      @include mobile {
        padding-top: 8rem;
      }
    }
  }

  + .section--banner {
    padding-top: 14rem;
    @include mobile {
      padding-top: 6rem;
    }
  }

  .text-block {
    + .list {
      margin-top: 8rem;

      @include mobile {
        margin-top: 6rem;
      }
    }
  }
}
