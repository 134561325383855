.cookie {
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 2rem !important;
  left: 2rem;
  background-color: #c1d8db;
  width: 100%;
  max-width: 34rem;
  padding: 2rem;
  z-index: 9999;

  @include mobile {
    max-width: unset;
    left: 0;
    bottom: 0 !important;
  }

  &__body {
  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 5rem;
    padding: 0 3rem;
    margin-top: 2.7rem;
    cursor: pointer;
    user-select: none;
    color: $white;
    font-size: 1.6rem;
    font-weight: 500;
    white-space: nowrap;
    background-color: $green-dark;
    border-radius: 10rem;
  }
}
