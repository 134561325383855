html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%
}
body {
  margin: 0
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block
}
audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline
}
audio:not([controls]) {
  display: none;
  height: 0
}
[hidden],
template {
  display: none
}
a {
  background-color: transparent
}
a:active,
a:hover {
  outline: 0
}
abbr[title] {
  border-bottom: 1px dotted
}
b,
strong {
  font-weight: 700
}
dfn {
  font-style: italic
}
h1 {
  font-size: 2em;
  margin: .67em 0
}
mark {
  background: #ff0;
  color: #000
}
small {
  font-size: 80%
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline
}
sup {
  top: -.5em
}
sub {
  bottom: -.25em
}
img {
  border: 0
}
svg:not(:root) {
  overflow: visible
}
figure {
  margin: 1em 40px
}
hr {
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0
}
pre {
  overflow: auto
}
code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em
}
button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0
}
button {
  overflow: visible
}
button,
select {
  text-transform: none
}
button,
html input[type=button],
input[type=reset],
input[type=submit] {
  -webkit-appearance: button;
  cursor: pointer
}
button[disabled],
html input[disabled] {
  cursor: default
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0
}
input {
  line-height: normal
}
input[type=checkbox],
input[type=radio] {
  box-sizing: border-box;
  padding: 0
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  height: auto
}
input[type=search] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box
}
input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration {
  -webkit-appearance: none
}
fieldset {
  border: 1px solid silver;
  margin: 0 2px;
  padding: .35em .625em .75em
}
legend {
  border: 0;
  padding: 0
}
textarea {
  overflow: auto
}
optgroup {
  font-weight: 700
}
table {
  border-collapse: collapse;
  border-spacing: 0
}
td,
th {
  padding: 0
}

blockquote, q{
  margin:0;
  padding:0;
  border:0;
  outline:0;
  font-size:100%;
  vertical-align:baseline;
  background:transparent;
  quotes:none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content:'';
  content:none;
}

input,
textarea{
  border: none;
}

button {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  text-align: inherit;

  background: transparent;

  /* inherit font & color from ancestor */
  color: inherit;
  font: inherit;

  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal;

  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  /* Corrects inability to style clickable `input` types in iOS */
  -webkit-appearance: none;

  /* Remove excess padding and border in Firefox 4+ */
  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
}



