.ReactModal {
  &__Body--open {
    overflow: hidden;
  }

  &__Overlay {
    display: flex;
    justify-content: center;
    opacity: 0;
    transition: opacity 400ms ease-in-out;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.15) !important;
  }

  &__Overlay--after-open {
    opacity: 1;
  }

  &__Overlay--before-close {
    opacity: 0;
  }

  &__Content {
    align-self: center;
    width: 100%;
    max-width: 83.4rem;
    max-height: 100%;
    padding: 6rem 8.5rem 8rem 6rem !important;
    border: none !important;
    border-radius: unset !important;
    position: relative !important;
    top: unset !important;
    left: unset !important;
    right: unset !important;
    bottom: unset !important;

    @include mobile {
      padding: 4rem 2rem !important;
      height: 100%;
    }

    .sub-title {
      margin-bottom: 4rem;
    }
  }

  &__close {
    position: absolute;
    top: 3rem;
    right: 3rem;
    font-size: 0;

    @include mobile {
      right: 2.4rem;

      &::after {
        content: '';
        display: block;
        width: 5rem;
        height: 5rem;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    @include desktop {
      &:hover {
        #ic_close {
          stroke: $green !important;
        }
      }
    }

    svg {
      width: 2rem;
      height: 2rem;
      #ic_close {
        transition: stroke $trans;
      }
    }
  }
}
