.list {
  &--nav,
  &--lang {
    display: flex;

    & > .list {
      &__item {
        + .list__item {
          margin-left: 3rem;
        }
      }

      &__link {
        font-size: 1.6rem;
        font-weight: 500;
        line-height: calc(24 / 16);
        color: $green-dark;
      }
    }
  }

  &--nav {
    @include mobile {
      flex-direction: column;
      position: absolute;
      padding: 2rem 2rem 4rem 2rem;
      margin-top: 8rem;
      left: 0;
      top: 0;
      width: 100%;
      background-color: $white;
    }

    & > .list {
      &__item {
        .link {
          @include mobile {
            font-size: 2.6rem;
            line-height: calc(3.6 / 2.6);
          }
        }
        + .list__item {
          @include mobile {
            margin-left: 0;
            margin-top: 2rem;
          }
        }
      }
    }
  }

  &--lang {
    & > .list {
      &__item {
        + .list__item {
          @include mobile {
            margin-left: 2rem;
          }
        }
      }
    }
  }

  &--foot-nav {
    display: flex;
    .list {
      &__item {
        + .list__item {
          margin-left: 3rem;

          @include mobile {
            margin-left: 0;
            margin-top: 2rem;
          }
        }
      }
    }
  }

  &--activity {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding-bottom: 12rem;
    border-bottom: 2px solid $green-light;

    @include mobile {
      justify-content: space-between;
      padding-bottom: 6rem;
    }

    .list {
      &__item {
        width: 15.4rem;
        margin-left: 10rem;
        user-select: none;
        cursor: pointer;

        @include mobile {
          width: 42%;
          min-width: 11.8rem;
          margin-left: 0;
        }

        @include desktop {
          &:hover {
            .icon--hover {
              opacity: 1;
            }
          }
        }

        &:nth-of-type(4n) {
          margin-left: 0;
        }
        &:first-of-type {
          margin-left: 0;
        }
        &:nth-of-type(n + 4) {
          margin-top: 8rem;
          @include mobile {
            margin-top: 6rem;
          }
        }

        &:nth-of-type(n + 3) {
          @include mobile {
            margin-top: 6rem;
          }
        }
      }
    }

    .icon {
      display: block;
      width: 15.4rem;
      padding-bottom: 100%;
      margin-bottom: 2rem;
      @include background-contain;
      position: relative;

      @include mobile {
        width: 100%;
      }

      &--hover {
        @include sheet;
        @include background-contain;
        opacity: 0;
        transition: opacity $trans;
      }
    }

    .heading {
      text-align: center;
    }
  }

  &--people {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% + 20rem);
    @include mobile {
      width: 100%;
    }

    .list {
      &__item {
        width: 50%;
        @include mobile {
          width: 100%;
        }

        &:nth-of-type(n + 3) {
          margin-top: 8rem;
        }

        &:not(:first-of-type) {
          @include mobile {
            margin-top: 4.4rem;
          }
        }
      }
    }

    .avatar {
      display: block;
      margin-bottom: 2rem;
      width: 15.4rem;
      height: 15.4rem;
      border-radius: 50%;
      @include background-cover;

      @include mobile {
        min-width: 11.8rem;
        width: 42%;
        height: auto;
        padding-bottom: 42%;
      }
    }

    .text-block {
      padding-left: 2rem;
      border-left: 2px solid $green-light;
      h3 {
        + p {
          margin-top: 0.5rem;
        }
      }

      p {
        + ul {
          margin-top: 2rem;
        }
      }

      ul {
        li {
          margin-bottom: 0.3rem !important;
          padding-left: 0;
          &::before {
            display: none;
          }
        }
      }
      a,
      &__header-link {
        &:after {
          animation: moveOut 0.32s ease-in-out forwards;
        }

        @include desktop {
          &:hover {
            &:after {
              animation: moveIn 0.32s ease-in-out forwards;
            }
          }
        }
      }
    }
  }

  &--career {
    display: flex;
    flex-direction: column;
    width: 100%;

    .list {
      &__item {
        width: 100%;

        + .list__item {
          margin-top: 2.4rem;

          @include mobile {
            margin-top: 2.2rem;
          }
        }
      }

      &__download {
        display: flex;
        width: 100%;
        padding-bottom: 2.4rem;
        border-bottom: 2px solid $green-light;
        transition: border 450ms ease-in-out;
        position: relative;
        user-select: none;

        @include mobile {
          padding-bottom: 2rem;
        }

        @include desktop {
          &:hover {
            border-color: $green-dark;

            .icon {
              opacity: 1;
            }
          }
        }
      }
    }

    .text-block {
      h3 {
        + p {
          margin-top: 0.5rem;

          @include mobile {
            margin-top: 0.2rem;
          }
        }
      }
    }

    .icon {
      display: block;
      width: 3rem;
      height: 4rem;
      opacity: 0;
      transition: opacity 300ms ease-in-out;
      position: absolute;
      top: 0.7rem;
      right: 2rem;

      svg {
        width: 3rem;
        height: 4rem;
      }
    }
  }
}
