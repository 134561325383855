.show-more {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 4rem;

  &__button {
    font-size: 1.4rem;
    font-weight: 500;
    display: flex;
    align-items: center;
  }

  &__arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 1rem;
    width: 1.2rem;
    height: 1.2rem;
    transition: transform $trans;

    svg {
      width: 100%;
    }
  }

  &--less {
    .show-more__arrow {
      transform: rotate(180deg);
    }
  }
}
