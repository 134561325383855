.link {
  display: inline-block;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 2.6rem;
  user-select: none;
  position: relative;
  overflow: hidden;
  cursor: pointer;

  @include mobile {
    font-size: 1.4rem;
  }

  &:after {
    display: block;
    position: absolute;
    bottom: 0;
    left: -2px;
    // right: 100%;
    width: calc(100% + 4px);
    height: 2px;
    transition: none;
    content: '';
    animation: moveOut 0.32s ease-in-out forwards;
    animation-delay: -0.32s;
  }

  @include desktop {
    &:hover {
      &::after {
        animation: moveIn 0.32s ease-in-out forwards;
      }
    }
  }

  &--reverse {
    &:after {
      animation: moveIn 0.32s ease-in-out forwards;
    }

    @include desktop {
      &:hover {
        &::after {
          animation: moveOut 0.32s ease-in-out forwards;
        }
      }
    }
  }

  &--white {
    color: $white;
    &::after {
      background-color: $white;
    }
  }

  &--green {
    color: $green-dark;
    &::after {
      background-color: $green-dark;
    }
  }

  &.active {
    &::after {
      animation: moveIn 0.32s ease-in-out forwards;
    }
  }
}

@keyframes moveIn {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes moveOut {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(100%);
  }
}
