.header {
  width: 100%;
  padding: 2rem 0;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  transition: background 300ms ease-in-out, padding 300ms ease-in-out;
  will-change: background, padding;

  &__inner {
    display: flex;
    align-items: center;
  }

  &__nav {
    display: flex;
    flex: 1;
    justify-content: space-between;
    @include mobile {
      flex: unset;
      margin-left: auto;
    }
  }

  .logo {
    margin-right: 12.2rem;
    flex-shrink: 0;
    position: relative;
    z-index: 3;
    @include mobile {
      margin-right: 3rem;
    }
  }

  .list__item.isActive {
    .link--green {
      &::after {
        animation: moveIn 0.32s ease-in-out forwards;
      }
    }
  }

  .list--nav {
    @include mobile {
      transform: scaleY(0);
      transition: $trans, opacity 150ms ease-in-out;
      transform-origin: center top;
      pointer-events: none;
      opacity: 0;
      visibility: hidden;
    }
  }

  &.sticky {
    background-color: $white;
    padding: 1.5rem 0;
    box-shadow: 0 1rem 2rem #0000000d;
    @include mobile {
      padding: 2rem 0;
    }

    .logo {
      height: 5rem;
      @include mobile {
        height: 4rem;
      }
    }
  }
}

@include mobile {
  .header {
    &__menu {
      display: flex;
      align-items: center;
      justify-content: center;

      margin-left: 3.3rem;
      z-index: 101;
      user-select: none;
      i {
        display: block;
        width: 100%;
        height: 2px;
        background-color: $green-dark;
        pointer-events: none;
        transition: opacity 250ms ease-in-out, transform 250ms ease-in-out, background-color 600ms ease-in-out;
      }
      span {
        display: flex;
        align-items: center;
        position: relative;
        width: 3rem;
        height: 2rem;
        pointer-events: none;
        &:before,
        &:after {
          content: '';
          display: block;
          width: 100%;
          height: 2px;
          background-color: $green-dark;
          position: absolute;
          left: 0;
          transition: transform 400ms ease-in-out, top 400ms ease-in-out, background-color 600ms ease-in-out;
        }
        &:before {
          top: 0;
        }
        &:after {
          top: 85%;
        }
      }
      &:after {
        content: '';
        display: block;
        position: absolute;
        width: 5rem;
        height: 5rem;
      }
    }
  }

  .menu-open {
    .header__menu {
      span {
        &:before,
        &:after {
          transition: transform 400ms ease-in-out 220ms, top 200ms ease-in-out;
        }
        &:before {
          top: 50%;
          transform: rotate(45deg);
        }
        &:after {
          top: 50%;
          bottom: unset;
          transform: rotate(-45deg);
        }
      }
      i {
        //transform: translateX(100%);
        opacity: 0;
      }
    }

    .header {
      &:after {
        content: '';
        position: absolute;
        @include sheet;
        background-color: $white;
        z-index: -1;
      }
    }

    .list--nav {
      transform: scaleY(1);
      pointer-events: auto;
      opacity: 1;
      visibility: visible;
    }
  }
}
