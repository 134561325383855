.scroll-to {
  display: flex;
  align-items: center;
  user-select: none;
  cursor: pointer;
  svg {
    height: 5rem;
    animation: bounce-arrow 2s infinite linear;
  }

  span {
    display: inline-block;
    font-size: 1.6rem;
    font-weight: 500;
    margin-left: 2rem;
    white-space: nowrap;
  }
}

@keyframes bounce-arrow {
  0% {
    transform: translateY(-0.4rem);
  }
  30% {
    transform: translateY(0.2rem);
  }

  42% {
    transform: translateY(0.4rem);
  }

  55% {
    transform: translateY(0.2rem);
  }
  100% {
    transform: translateY(-0.4rem);
  }
}
